// src/components/auth/EmailVerifiedError.js
import React from 'react';
import { Link } from 'react-router-dom';
import { AlertCircle, RefreshCw } from 'lucide-react';

export const EmailVerifiedError = () => {
  const handleResendVerification = async () => {
    try {
      const response = await fetch('/sort/auth/resend-verification/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        alert('Verification email has been resent. Please check your inbox.');
      } else {
        alert('Failed to resend verification email. Please try again.');
      }
    } catch (error) {
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-lg shadow-md">
        <div className="text-center">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
            <AlertCircle className="h-6 w-6 text-red-600" />
          </div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Verification Failed
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            There was a problem verifying your email address. This might happen if the verification link has expired or was already used.
          </p>
        </div>

        <div className="mt-8 space-y-4">
          <button
            onClick={handleResendVerification}
            className="w-full flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <RefreshCw className="h-5 w-5 mr-2" />
            Resend verification email
          </button>

          <p className="text-center">
            <Link
              to="/login"
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              Return to sign in
            </Link>
          </p>

          <div className="mt-6">
            <p className="text-center text-sm text-gray-500">
                Need help?{' '}
                <a
                    href="mailto:support@linksort.ai"
                    className="font-medium text-blue-600 hover:text-blue-500"
                >
                    Contact support
                </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerifiedError;