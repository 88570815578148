// components/Contact.js
import React, { useState, useEffect } from "react";
import { Mail } from "lucide-react";

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInSeconds < 60) {
    return 'just now';
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
  } else if (diffInDays < 30) {
    return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
  } else {
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    });
  }
};

const Contact = () => {
  const [messages, setMessages] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [status, setStatus] = useState({
    loading: false,
    submitting: false,
    error: null
  });

  // 获取留言列表
  const fetchMessages = async () => {
    setStatus(prev => ({ ...prev, loading: true }));
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/sort/messages`);
      const data = await response.json();
      setMessages(data);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setStatus(prev => ({ 
        ...prev, 
        error: 'Failed to load messages. Please refresh the page.' 
      }));
    } finally {
      setStatus(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus(prev => ({ ...prev, submitting: true, error: null }));

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/sort/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          timestamp: new Date().toISOString()
        })
      });

      if (!response.ok) {
        throw new Error('Failed to post message');
      }

      // 清空表单
      setFormData({
        name: "",
        email: "",
        message: ""
      });

      // 刷新留言列表
      fetchMessages();

    } catch (error) {
      console.error('Error posting message:', error);
      setStatus(prev => ({
        ...prev,
        error: 'Failed to post message. Please try again.'
      }));
    } finally {
      setStatus(prev => ({ ...prev, submitting: false }));
    }
  };

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold text-center mb-8">Community Discussion</h1>

      <div className="flex justify-center mb-12">
        <ContactMethod
          icon={<Mail className="w-6 h-6" />}
          title="Email"
          description="support@linksort.ai"
          link="mailto:support@linksort.ai"
        />
      </div>

      {/* Message Form */}
      <div className="bg-white p-8 rounded-lg shadow-lg mb-8">
        <h2 className="text-2xl font-semibold mb-6">Leave a Message</h2>
        
        {status.error && (
          <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
            {status.error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-3 border rounded-lg"
                required
                disabled={status.submitting}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-3 border rounded-lg"
                required
                disabled={status.submitting}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Message
            </label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="4"
              className="w-full p-3 border rounded-lg"
              required
              disabled={status.submitting}
            ></textarea>
          </div>

          <button
            type="submit"
            className={`w-full px-6 py-3 rounded-lg text-white transition-colors ${
              status.submitting 
                ? 'bg-blue-400 cursor-not-allowed' 
                : 'bg-blue-600 hover:bg-blue-700'
            }`}
            disabled={status.submitting}
          >
            {status.submitting ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Posting...
              </span>
            ) : (
              'Post Message'
            )}
          </button>
        </form>
      </div>

      {/* Messages List */}
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-6">Community Messages</h2>
        
        {status.loading ? (
          <div className="text-center py-8">
            <svg className="animate-spin h-8 w-8 text-blue-600 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        ) : messages.length === 0 ? (
          <p className="text-center text-gray-500">No messages yet. Be the first to leave a message!</p>
        ) : (
          <div className="space-y-6">
            {messages.map((message) => (
              <div key={message._id} className="border-b pb-6">
                <div className="flex items-start justify-between mb-2">
                  <div>
                    <h3 className="font-medium">{message.name}</h3>
                    <p className="text-sm text-gray-500">
                      {formatTimestamp(message.timestamp)}
                    </p>
                  </div>
                </div>
                <p className="text-gray-700 whitespace-pre-wrap">{message.message}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const ContactMethod = ({ icon, title, description, link }) => {
  return (
    <a 
      href={link}
      className="flex flex-col items-center p-6 border rounded-lg hover:shadow-lg transition-shadow text-center"
    >
      <div className="mb-4 text-blue-600">{icon}</div>
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </a>
  );
};

export default Contact;