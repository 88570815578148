import React from "react";

const RefundPolicy = () => {
  return (
    <div>
      <h1>Refund Policy</h1>
        <p>At linksort.ai, we strive to provide the best possible experience for our users. If you are not satisfied with our service, you may request a refund under the following conditions:</p>

        <h2>Eligibility for Refunds</h2>
        <p>Refunds may be requested within 7 days of purchase if the service does not meet your expectations, or if there are technical issues that prevent the service from functioning as advertised.</p>

        <h2>How to Request a Refund</h2>
        <p>To request a refund, please contact our support team at support@linksort.ai with your order details and the reason for the refund request.</p>

        <h2>Processing Refunds</h2>
        <p>Once your refund request is approved, we will process the refund within 5-7 business days using the original payment method.</p>

        <h2>Non-Refundable Services</h2>
        <p>Custom integrations or any services where work has already been performed may not be eligible for refunds.</p>

        <h2>Contact</h2>
        <p>If you have any questions about this refund policy, please contact us at: support@linksort.ai</p>

    </div>
  );
};

export default RefundPolicy;
