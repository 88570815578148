// src/components/auth/RegisterForm.js

import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Mail, Lock, Eye, EyeOff, AlertCircle, Loader2 } from 'lucide-react';
import { useAuth } from './AuthContext';

// Form Input Component
const FormInput = ({
  icon: Icon,
  label,
  register,
  name,
  validation,
  type,
  error,
  showPasswordToggle,
  onTogglePassword,
  showPassword
}) => (
  <div className="relative">
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <div className="mt-1 relative rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <Icon className="h-5 w-5 text-gray-400" />
      </div>
      <input
        id={name}
        {...register(name, validation)}
        type={type}
        className={`focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 pr-10 sm:text-sm border-gray-300 rounded-md ${
          error ? 'border-red-300' : ''
        }`}
      />
      {showPasswordToggle && (
        <button
          type="button"
          className="absolute inset-y-0 right-0 pr-3 flex items-center"
          onClick={onTogglePassword}
        >
          {showPassword ? (
            <EyeOff className="h-5 w-5 text-gray-400" />
          ) : (
            <Eye className="h-5 w-5 text-gray-400" />
          )}
        </button>
      )}
    </div>
    {error && (
      <p className="mt-1 text-sm text-red-600">{error.message}</p>
    )}
  </div>
);

export const RegisterForm = () => {
  const navigate = useNavigate();
  const { register: registerUser } = useAuth();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState('');
  const [fieldErrors, setFieldErrors] = React.useState({});

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: '',
      password1: '',
      password2: ''
    },
    mode: 'onChange' // 启用实时验证
  });

  const password = watch('password1');

  // 处理服务器返回的字段错误
  const handleFieldErrors = (errors) => {
    clearErrors(); // 清除之前的错误
    setFieldErrors({}); // 清除之前的字段错误

    Object.entries(errors).forEach(([field, messages]) => {
      if (Array.isArray(messages) && messages.length > 0) {
        const fieldName = field === 'login' ? 'email' : field; // 映射字段名
        setError(fieldName, {
          type: 'server',
          message: messages[0]
        });
        setFieldErrors(prev => ({
          ...prev,
          [fieldName]: messages[0]
        }));
      }
    });
  };

  // 获取字段错误
  const getFieldError = (fieldName) => {
    return errors[fieldName] || 
           (fieldErrors[fieldName] && { 
             message: fieldErrors[fieldName] 
           });
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setServerError('');
    clearErrors();
    setFieldErrors({});

    try {
      console.log('Submitting registration data:', data); // 调试日志

      const response = await registerUser({
        email: data.email,
        password1: data.password1,
        password2: data.password2
      });

      console.log('Registration response:', response); // 调试日志

      // 确保响应格式正确
      if (response && response.success) {
        // 根据响应处理不同的导航情况
        if (response.location) {
          if (response.location.includes('verify-email') || response.location === '/verify-email') {
            navigate('/verify-email', {
              state: {
                email: data.email,
                message: response.message || 'Please check your email to verify your account.',
                requiresVerification: true
              },
              replace: true
            });
          } else {
            navigate(response.location, { replace: true });
          }
        } else {
          navigate('/app', { replace: true });
        }
        return;
      }

      // 如果响应中没有 success 字段或为 false
      throw new Error(response?.message || 'Registration failed');

    } catch (error) {
      console.error('Registration error:', error);

      // 处理服务器返回的错误
      if (error.errors) {
        handleFieldErrors(error.errors);
      }

      // 设置通用错误消息
      setServerError(
        error.message || 
        'Registration failed. Please check your information and try again.'
      );

    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-lg shadow-md">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Create your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Already have an account?{' '}
            <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500">
              Sign in
            </Link>
          </p>
        </div>

        {serverError && (
          <div className="bg-red-50 p-4 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
            <span className="text-red-700">{serverError}</span>
          </div>
        )}

        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormInput
            icon={Mail}
            label="Email address"
            register={register}
            name="email"
            type="email"
            validation={{
              required: 'Email is required',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Please enter a valid email'
              }
            }}
            error={getFieldError('email')}
          />

          <div className="space-y-4">
            <FormInput
              icon={Lock}
              label="Password"
              register={register}
              name="password1"
              type={showPassword ? "text" : "password"}
              validation={{
                required: 'Password is required',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters'
                }
              }}
              error={getFieldError('password1')}
              showPasswordToggle
              onTogglePassword={() => setShowPassword(!showPassword)}
              showPassword={showPassword}
            />

            <FormInput
              icon={Lock}
              label="Confirm password"
              register={register}
              name="password2"
              type={showConfirmPassword ? "text" : "password"}
              validation={{
                required: 'Please confirm your password',
                validate: value => value === password || 'Passwords do not match'
              }}
              error={getFieldError('password2')}
              showPasswordToggle
              onTogglePassword={() => setShowConfirmPassword(!showConfirmPassword)}
              showPassword={showConfirmPassword}
            />
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isSubmitting ? (
              <>
                <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                Creating account...
              </>
            ) : (
              'Create account'
            )}
          </button>

          <div className="text-xs text-center text-gray-600">
            By creating an account, you agree to our{' '}
            <Link to="/terms" className="text-blue-600 hover:text-blue-500">
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link to="/privacy" className="text-blue-600 hover:text-blue-500">
              Privacy Policy
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterForm;