// componenets/browserIdService.js
class BrowserIdService {
    static BROWSER_ID_KEY = 'browser_id';

    static generateBrowserId() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    }

    static getBrowserId() {
        // 首先尝试从cookie获取
        const cookies = document.cookie.split(';');
        let browserId = cookies
            .find(cookie => cookie.trim().startsWith(`${this.BROWSER_ID_KEY}=`))
            ?.split('=')[1];

        // 如果cookie中没有，检查localStorage
        if (!browserId) {
            browserId = localStorage.getItem(this.BROWSER_ID_KEY);
        }

        // 如果都没有，生成新的
        if (!browserId || !this.isValidBrowserId(browserId)) {
            browserId = this.generateBrowserId();
            // 只在localStorage中保存作为备份
            localStorage.setItem(this.BROWSER_ID_KEY, browserId);
        }

        return browserId;
    }

    static setAxiosHeaders(axiosInstance) {
        const browserId = this.getBrowserId();
        if (axiosInstance) {
            // 设置请求头
            axiosInstance.defaults.headers.common['X-Browser-ID'] = browserId;
            // 确保开启withCredentials以接收cookie
            axiosInstance.defaults.withCredentials = true;
        }
    }

    static removeBrowserId() {
        // 同时清除localStorage和cookie
        localStorage.removeItem(this.BROWSER_ID_KEY);
        document.cookie = `${this.BROWSER_ID_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }

    static isValidBrowserId(browserId) {
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return uuidRegex.test(browserId);
    }
}

export default BrowserIdService;