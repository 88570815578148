// components/categoryUtils.js
import axios from 'axios';
import { cosineSimilarity } from './similarity';
import { getEmbeddingFromIndexedDB, getCategoryEmbeddingFromIndexedDB, getCategoriesFromIndexedDB } from './indexeddb'; // 从 indexeddb.js 中导入

// 处理分类比较逻辑
export async function handleCompareWithCategories(newEmbedding) {
    try {
        const storedCategories = await getCategoriesFromIndexedDB();  // 从 IndexedDB 获取分类列表
        let maxSimilarity = -1;
        let bestCategory = null;

        for (const category of storedCategories) {
            console.log("Comparing category object:", category);

            // 正确地引用 category 字段
            const categoryName = category.category;
            console.log("Comparing category:", categoryName);

            const urls = category.urls || [];  // 获取分类下所有的 URL，如果没有 URL，则使用空数组

            if (urls.length > 0) {
                // 获取该分类下所有 URL 的嵌入向量，并计算与新 URL 嵌入的平均相似度
                const embeddings = await Promise.all(urls.map(url => getEmbeddingFromIndexedDB(url)));
                const validEmbeddings = embeddings.filter(embedding => embedding !== null);
                
                if (validEmbeddings.length > 0) {
                    const similarities = validEmbeddings.map(embedding => cosineSimilarity(newEmbedding, embedding));
                    const averageSimilarity = similarities.reduce((a, b) => a + b, 0) / similarities.length;
                    
                    console.log(`Average similarity with category ${categoryName}: ${averageSimilarity}`);
                    
                    // 更新最相似的分类
                    if (averageSimilarity > maxSimilarity) {
                        maxSimilarity = averageSimilarity;
                        bestCategory = { name: categoryName, similarity: averageSimilarity };
                    }
                }
            } else {
                // 如果该分类没有 URL 嵌入，则比较分类本身的嵌入
                const categoryEmbedding = await getCategoryEmbeddingFromIndexedDB(categoryName);  // 从 IndexedDB 获取分类嵌入
                if (categoryEmbedding) {
                    const similarity = cosineSimilarity(newEmbedding, categoryEmbedding);
                    console.log(`Similarity with category ${categoryName} embedding: ${similarity}`);
                    
                    if (similarity > maxSimilarity) {
                        maxSimilarity = similarity;
                        bestCategory = { name: categoryName, similarity: similarity };
                    }
                }
            }
        }

        if (bestCategory) {
            console.log(`Best matching category: ${bestCategory.name} with similarity: ${bestCategory.similarity}`);
        } else {
            console.log('No matching category found.');
        }

        return bestCategory;

    } catch (error) {
        console.error('Error comparing embeddings with categories:', error);
    }
}

// 从服务器生成分类嵌入
export async function generateCategoryEmbedding(categoryName, apiUrl, csrfToken) {
    try {
        const response = await axios.post(
            `${apiUrl}/sort/generateCategoryEmbedding/`,
            { category: categoryName },
            {
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            }
        );

        const embedding = response.data.embedding;
        console.log(`Generated embedding for category ${categoryName}:`, embedding);
        return embedding;
    } catch (error) {
        console.error('Error generating category embedding:', error);
        return null;
    }
}
