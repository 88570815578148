// components/LandingPage.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Link2,
  BookOpen,
  Newspaper,
  Share2,
  Sparkles,
  ArrowRight,
  Check,
  LogIn,
  UserPlus
} from "lucide-react";
import '../css/LandingPage.css';

// Constants
const EXAMPLES = {
  news: {
    originalUrl: "https://example.com/tech-news/ai-developments",
    title: "Recent Developments in Artificial Intelligence",
    summary: "Key points:\n• OpenAI announces new language model capabilities\n• Breakthrough in AI-powered medical diagnosis\n• Discussions on AI safety and regulation\n• Impact on future workforce development",
    related: [
      { title: "AI Safety Guidelines Released", url: "#" },
      { title: "Medical AI: A New Era", url: "#" },
      { title: "Future of Work in AI Age", url: "#" }
    ]
  },
  research: {
    originalUrl: "https://example.com/research/climate-study",
    title: "Climate Change Impact Study 2024",
    summary: "Key findings:\n• Global temperature trends analysis\n• Impact on biodiversity\n• Mitigation strategies\n• Economic implications of climate action",
    related: [
      { title: "Renewable Energy Trends", url: "#" },
      { title: "Biodiversity Loss Report", url: "#" },
      { title: "Economic Models for Climate Action", url: "#" }
    ]
  },
  article: {
    originalUrl: "https://example.com/business/startup-guide",
    title: "Essential Guide to Building a Startup",
    summary: "Main takeaways:\n• Market research importance\n• Funding strategies\n• Team building essentials\n• Growth metrics to track",
    related: [
      { title: "Startup Funding 101", url: "#" },
      { title: "Building Strong Teams", url: "#" },
      { title: "Key Metrics for Startups", url: "#" }
    ]
  }
};

// Components
const LoadingSpinner = () => (
  <svg className="loading-spinner" viewBox="0 0 24 24">
    <circle
      className="spinner-circle"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
      fill="none"
    />
    <path
      className="spinner-path"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

const Navigation = ({ navigate }) => (
  <nav className="nav-container">
    <div className="logo-section">
      <Link to="/" className="flex items-center">
        <Link2 className="logo-icon" />
        <span className="logo-text">LinkSort.ai</span>
      </Link>
    </div>
    <div className="nav-buttons">
      <button 
        className="nav-btn outline flex items-center" 
        onClick={() => navigate('/login')}
      >
        <LogIn className="w-4 h-4 mr-2" />
        Sign In
      </button>
      <button 
        className="nav-btn primary flex items-center" 
        onClick={() => navigate('/register')}
      >
        <UserPlus className="w-4 h-4 mr-2" />
        Sign Up
      </button>
    </div>
  </nav>
);

const HeroSection = () => (
  <div className="hero-section">
    <div className="beta-badge">
      <Sparkles className="beta-icon" />
      <span>Now in public beta</span>
    </div>
    <h1 className="hero-title">Transform How You Process Web Content</h1>
    <p className="hero-description">
      Get instant AI-powered summaries and discover related content from any webpage.
      Save time and never miss important insights.
    </p>
  </div>
);

const DemoSection = React.memo(({ url, setUrl, isLoading, error, handleUrlSubmit }) => {
  console.log('[DemoSection] Rendering with:', { url, isLoading, error });
  
  const onSubmit = useCallback((e) => {
    e.preventDefault();
    
    if (!url.trim()) {
      // 聚焦输入框
      e.target.querySelector('input').focus();
      return;
    }
    
    console.log('[DemoSection] Submitting URL:', url);
    handleUrlSubmit(e);
  }, [url, handleUrlSubmit]);

  const onChange = useCallback((e) => {
    console.log('[DemoSection] URL input changed:', e.target.value);
    setUrl(e.target.value);
  }, [setUrl]);

  return (
    <div className="demo-section">
      <form onSubmit={onSubmit} className="demo-form">
        <input
          type="url"
          placeholder="Enter any webpage URL..."
          value={url}
          onChange={onChange}
          className="url-input"
          required
          pattern="https?://.*"
          title="Please enter a valid URL starting with http:// or https://"
          disabled={isLoading}
        />
        <button 
          type="submit" 
          disabled={isLoading} 
          className={`submit-btn ${!url.trim() ? 'empty-url' : ''} ${isLoading ? 'loading' : ''}`}
          title={!url.trim() ? 'Please enter a URL to analyze' : ''}
        >
          {isLoading ? 'Processing...' : 'Try Demo'}
        </button>
      </form>
      {error && <p className="error-message">{error}</p>}
      {isLoading && (
        <p className="text-sm text-gray-600 mt-2 text-center">
          Redirecting to summary page...
        </p>
      )}
      <div className="mt-4 text-center">
        <p className="demo-hint mb-2">Try it now - No signup required</p>
        <p className="text-sm text-gray-600">
          Want to save your summaries?{' '}
          <Link
            to="/register"
            className="text-blue-600 hover:text-blue-700 font-medium"
          >
            Create an account
          </Link>
        </p>
      </div>
    </div>
  );
});

const FeaturesSection = () => (
  <div className="features-section">
    <div className="feature-card">
      <BookOpen className="feature-icon" />
      <h3 className="feature-title">Smart Summaries</h3>
      <p className="feature-description">
        Save hours of reading time with AI-powered summaries that capture key points.
      </p>
    </div>

    <div className="feature-card">
      <Newspaper className="feature-icon" />
      <h3 className="feature-title">Related Content</h3>
      <p className="feature-description">
        Discover relevant articles and research tailored to your interests.
        Never miss important connections.
      </p>
    </div>

    <div className="feature-card">
      <Share2 className="feature-icon" />
      <h3 className="feature-title">Easy Sharing</h3>
      <p className="feature-description">
        Share insights with your team in one click. Perfect for researchers,
        analysts, and curious minds.
      </p>
    </div>
  </div>
);

const ExamplesSection = ({ activeTab, setActiveTab }) => (
  <div className="examples-section">
    <h2 className="section-title">See It In Action</h2>
    <div className="tabs">
      <div className="tab-buttons">
        {['news', 'research', 'article'].map(tab => (
          <button
            key={tab}
            className={`tab-btn ${activeTab === tab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
            {tab === 'news' ? ' Article' : tab === 'article' ? ' Post' : ' Paper'}
          </button>
        ))}
      </div>
      <div className="tab-content">
        <div className="example-grid">
          <div className="example-card">
            <div className="example-header">
              <h3 className="example-title">Original Article</h3>
              <p className="example-url">{EXAMPLES[activeTab].originalUrl}</p>
            </div>
            <div className="example-content">
              <h4 className="content-title">{EXAMPLES[activeTab].title}</h4>
              <div className="preview-placeholder">
                Original article preview
              </div>
            </div>
          </div>

          <div className="example-card">
            <div className="example-header">
              <h3 className="example-title">AI Summary & Recommendations</h3>
            </div>
            <div className="example-content">
              <div className="summary-section">
                <h4 className="content-title">Summary</h4>
                <p className="summary-text">
                  {EXAMPLES[activeTab].summary}
                </p>
              </div>
              <div className="related-section">
                <h4 className="content-title">Related Content</h4>
                <ul className="related-list">
                  {EXAMPLES[activeTab].related.map((item, index) => (
                    <li key={index} className="related-item">
                      <a href={item.url} className="related-link">
                        {item.title}
                        <ArrowRight className="arrow-icon" />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const SignupSection = ({ email, setEmail, error, isSubscribing, handleEmailSubmit, navigate }) => (
  <div className="signup-section">
    <h2 className="section-title">Get Early Access</h2>
    <p className="section-description">
      Join users who are already transforming how they process web content.
    </p>
    
    <div className="signup-container">
      <div className="flex flex-col items-center space-y-4">
        <button
          onClick={() => navigate('/register')}
          className="w-full max-w-md flex justify-center items-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <UserPlus className="w-5 h-5 mr-2" />
          Start Free Trial
        </button>
        <span className="text-sm text-gray-500">or</span>
        <button
          onClick={() => navigate('/login')}
          className="w-full max-w-md flex justify-center items-center py-3 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <LogIn className="w-5 h-5 mr-2" />
          Sign In to Your Account
        </button>
      </div>

      <div className="mt-8 benefits-grid">
        <div className="benefit-item">
          <Check className="check-icon" />
          <span>Free plan available</span>
        </div>
        <div className="benefit-item">
          <Check className="check-icon" />
          <span>No credit card required</span>
        </div>
        <div className="benefit-item">
          <Check className="check-icon" />
          <span>Access to core features</span>
        </div>
      </div>

      <div className="mt-6 text-center text-sm text-gray-500">
        Need more summaries? Check out our{' '}
        <a href="/pricing" className="text-blue-600 hover:text-blue-700 font-medium">
          Pro plans
        </a>
      </div>
    </div>
  </div>
);

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-links">
          <Link to="/features" className="footer-link">Features</Link>
          <span className="footer-separator">·</span>
          <Link to="/help" className="footer-link">Help Center</Link>
          <span className="footer-separator">·</span>
          <Link to="/contact" className="footer-link">Contact</Link>
          <span className="footer-separator">·</span>
          <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
          <span className="footer-separator">·</span>
          <Link to="/terms-of-service" className="footer-link">Terms of Service</Link>
          <span className="footer-separator">·</span>
          <Link to="/refund-policy" className="footer-link">Refund Policy</Link>
        </div>
        <div className="footer-copyright">
          © 2024 LinkSort.ai. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

const Landing = () => {
  const navigate = useNavigate();
  const isSubmitting = useRef(false);
  
  const [state, setState] = useState({
    url: '',
    email: '',
    isLoading: false,
    isSubscribing: false,
    error: '',
    activeTab: 'news'
  });

  const { url, email, isLoading, isSubscribing, error, activeTab } = state;

  // URL 验证函数
  const validateUrl = useCallback((inputUrl) => {
    console.log('[Landing] Validating URL:', inputUrl);
    if (!inputUrl.trim()) {
      return 'Please enter a URL';
    }
    try {
      new URL(inputUrl);
      return null;
    } catch {
      return 'Please enter a valid URL';
    }
  }, []);

  // 移除 initialized 和 mountedRef，简化初始化逻辑
  useEffect(() => {
    const pendingUrl = sessionStorage.getItem('pendingUrl');
    if (pendingUrl) {
      setState(prev => ({ ...prev, url: pendingUrl }));
      sessionStorage.removeItem('pendingUrl');
    }
  }, []);

  const handleUrlSubmit = useCallback(async (e) => {
    e.preventDefault();
    console.log('[Landing] URL submission triggered:', { url, isSubmitting: isSubmitting.current });

    if (isSubmitting.current) {
      console.log('[Landing] Submission blocked - already submitting');
      return;
    }

    const urlError = validateUrl(url);
    if (urlError) {
      console.log('[Landing] URL validation failed:', urlError);
      setState(prev => ({ ...prev, error: urlError }));
      return;
    }

    try {
      isSubmitting.current = true;
      setState(prev => ({ ...prev, isLoading: true, error: '' }));
      console.log('[Landing] Storing URL and navigating:', url);
      
      // 确保在导航前设置 sessionStorage
      sessionStorage.setItem('pendingUrl', url);
      // 添加短暂延迟确保状态更新完成
      setTimeout(() => {
        navigate('/app');
      }, 100);
      
    } catch (err) {
      console.error('[Landing] Submission error:', err);
      setState(prev => ({
        ...prev,
        error: err.message || 'An error occurred',
        isLoading: false
      }));
      sessionStorage.removeItem('pendingUrl');
    } finally {
      isSubmitting.current = false;
    }
  }, [url, navigate, validateUrl]);

  // 状态更新处理器
  const setUrl = useCallback((newUrl) => {
    setState(prev => ({ ...prev, url: newUrl }));
  }, []);

  const setEmail = useCallback((newEmail) => {
    setState(prev => ({ ...prev, email: newEmail }));
  }, []);

  const setActiveTab = useCallback((newTab) => {
    setState(prev => ({ ...prev, activeTab: newTab }));
  }, []);

  // Email 处理
  const handleEmailSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (!email.includes('@')) {
      setState(prev => ({ ...prev, error: 'Please enter a valid email address' }));
      return;
    }

    setState(prev => ({ ...prev, isSubscribing: true, error: '' }));
    
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      setState(prev => ({ ...prev, email: '', isSubscribing: false }));
    } catch (err) {
      setState(prev => ({
        ...prev,
        error: 'Failed to join beta. Please try again.',
        isSubscribing: false
      }));
    }
  }, [email]);

  // 记录状态变化
  useEffect(() => {
    console.log('[Landing] State updated:', { url, isLoading, error });
  }, [url, isLoading, error]);

  return (
    <div className="landing-page">
      <Navigation navigate={navigate} />
      <main className="main-content">
        <HeroSection />
        <DemoSection
          url={url}
          setUrl={setUrl}
          isLoading={isLoading}
          error={error}
          handleUrlSubmit={handleUrlSubmit}
        />
        <FeaturesSection />
        <ExamplesSection
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <SignupSection
          email={email}
          setEmail={setEmail}
          error={error}
          isSubscribing={isSubscribing}
          handleEmailSubmit={handleEmailSubmit}
          navigate={navigate}
        />
      </main>
      <Footer />
    </div>
  );
};

export default Landing;