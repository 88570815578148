// components/syncService.js
import axios from 'axios';
import Cookies from 'js-cookie';
import BrowserIdService from './browserIdService';

// 初始化axios headers
BrowserIdService.setAxiosHeaders(axios);

// 创建一个队列来存储待同步的操作
let syncQueue = [];
let isSyncing = false;
let isOnline = navigator.onLine;

// 为每个操作添加重试计数器
const MAX_RETRIES = 3;  // 最大重试次数
const RETRY_DELAY = 5000;  // 重试延迟时间（毫秒）

// 处理同步队列
async function processSyncQueue() {
    if (isSyncing || syncQueue.length === 0 || !isOnline) return;
    
    isSyncing = true;
    const operation = syncQueue[0];
    
    // 初始化重试计数
    if (!operation.retryCount) {
        operation.retryCount = 0;
    }
    
    try {
        const browserId = BrowserIdService.getBrowserId();
        
        await axios.post(
            `${process.env.REACT_APP_API_URL}/sort/sync_operation/`,
            operation,
            {
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                    'Content-Type': 'application/json',
                    'X-Browser-ID': browserId
                },
                withCredentials: true
            }
        );
        
        // 成功后移除该操作
        syncQueue.shift();
        
    } catch (error) {
        console.error('Sync operation failed:', error);
        if (error.response) {
            console.error('Error response:', error.response.data);
        }
        
        operation.retryCount++;
        
        if (operation.retryCount >= MAX_RETRIES) {
            // 达到最大重试次数，移除该操作
            console.error(`Operation failed after ${MAX_RETRIES} retries, removing from queue:`, operation);
            syncQueue.shift();
            
            // 可以在这里添加失败通知或错误处理逻辑
            if (typeof operation.onFailure === 'function') {
                operation.onFailure(error);
            }
        } else {
            // 延迟后重试
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
        }
    } finally {
        isSyncing = false;
        
        if (syncQueue.length > 0) {
            processSyncQueue();
        }
    }
}

// 添加操作到同步队列
export function addToSyncQueue(operation, onFailure = null) {
    // 添加失败回调
    if (onFailure) {
        operation.onFailure = onFailure;
    }
    
    syncQueue.push(operation);
    if (isOnline) {
        processSyncQueue();
    }
}

// 获取当前队列状态
export function getSyncQueueStatus() {
    return {
        queueLength: syncQueue.length,
        isSyncing,
        isOnline
    };
}

// 网络状态监控
window.addEventListener('online', () => {
    isOnline = true;
    processSyncQueue();
});

window.addEventListener('offline', () => {
    isOnline = false;
});