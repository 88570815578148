// components/NewsList.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Tooltip,
  LinearProgress,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Typography,
  Grid,
  CircularProgress,
  Alert,
  Pagination,
  Chip,
  Snackbar,
  IconButton
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import '../css/NewsList.css';
import BrowserIdService from './browserIdService';
import RefreshIcon from '@mui/icons-material/Refresh';

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000;
const BASE_URL = process.env.REACT_APP_API_URL;

const NewsList = () => {
  const theme = useTheme();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [isRefreshing, setIsRefreshing] = useState(false);

  const showNotification = useCallback((message, severity = 'error') => {
    setSnackbar({ open: true, message, severity });
  }, []);

  const handleCloseSnackbar = useCallback(() => {
    setSnackbar(prev => ({ ...prev, open: false }));
  }, []);

  const api = useMemo(() => {
    const instance = axios.create({
      baseURL: BASE_URL,
      timeout: 10000,
      withCredentials: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
  
    // 设置browser_id请求头
    BrowserIdService.setAxiosHeaders(instance);
  
    // 添加请求拦截器
    instance.interceptors.request.use(
      (config) => {
        config.metadata = { startTime: new Date() };
        console.log(`[${new Date().toISOString()}] 请求开始:`, {
          url: config.url,
          method: config.method,
          headers: config.headers,
          data: config.data,
          timeout: config.timeout
        });
        return config;
      },
      (error) => {
        console.error(`[${new Date().toISOString()}] 请求拦截器错误:`, error);
        return Promise.reject(error);
      }
    );
  
    instance.interceptors.response.use(
      (response) => {
        const duration = new Date() - response.config.metadata.startTime;
        console.log(`[${new Date().toISOString()}] 请求完成:`, {
          url: response.config.url,
          method: response.config.method,
          status: response.status,
          statusText: response.statusText,
          duration: `${duration}ms`,
          responseHeaders: response.headers,
          contentLength: response.headers['content-length'],
          contentType: response.headers['content-type']
        });
  
        const browserIdCookie = response.headers['set-cookie']?.find(
          cookie => cookie.startsWith('browser_id=')
        );
        if (browserIdCookie) {
          const browserId = browserIdCookie.split('=')[1].split(';')[0];
          localStorage.setItem(BrowserIdService.BROWSER_ID_KEY, browserId);
        }
        return response;
      },
      async error => {
        const duration = error.config.metadata ? new Date() - error.config.metadata.startTime : 'unknown';
        console.error(`[${new Date().toISOString()}] 请求失败:`, {
          url: error.config?.url,
          method: error.config?.method,
          duration: `${duration}ms`,
          errorName: error.name,
          errorMessage: error.message,
          errorCode: error.code,
          status: error.response?.status,
          statusText: error.response?.statusText,
          responseHeaders: error.response?.headers,
          requestHeaders: error.config?.headers,
          timeout: error.config?.timeout,
          retryCount: error.config?._retry || 0
        });
  
        const originalRequest = error.config;
        
        if ((error.code === 'ERR_NETWORK' || error.response?.status === 502) && 
            (!originalRequest._retry || originalRequest._retry < MAX_RETRIES)) {
          originalRequest._retry = (originalRequest._retry || 0) + 1;
          console.log(`[${new Date().toISOString()}] 准备重试请求:`, {
            url: originalRequest.url,
            retryCount: originalRequest._retry,
            delay: RETRY_DELAY
          });
          
          const browserId = BrowserIdService.getBrowserId();
          if (browserId) {
            originalRequest.headers['X-Browser-ID'] = browserId;
          }
          
          await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
          return instance(originalRequest);
        }
        
        return Promise.reject(error);
      }
    );
  
    return instance;
  }, []);

  const fetchNews = useCallback(async (pageNum) => {
    const requestStartTime = new Date().toISOString();
    const browserId = BrowserIdService.getBrowserId();
    
    console.log(`[${requestStartTime}] 开始发起新闻获取请求`);
    
    // 构建请求配置
    const requestConfig = {
      headers: {
        ...api.defaults.headers,
        'X-Browser-ID': browserId
      },
      timeout: api.defaults.timeout,
      withCredentials: api.defaults.withCredentials
    };
    
    console.log('请求配置:', {
      url: `${BASE_URL}/sort/getNews/?page=${pageNum}`,
      method: 'GET',
      ...requestConfig
    });
  
    try {
      setLoading(true);
      setError(null);
      
      console.log('发送请求前状态:', {
        browserId,
        retryCount,
        loading: true,
        error: null,
        currentPage: pageNum
      });
      
      const response = await api.get(`/sort/getNews/?page=${pageNum}`, requestConfig);
      
      console.log('API 响应详情:', {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        data: response.data
      });
  
      const data = response.data;
  
      if (data.status === 'success') {
        let newsData = data.summarized_news?.summarized_news || data.summarized_news;
        let paginationData = data.summarized_news?.pagination || data.pagination;
  
        console.log('数据解析结果:', {
          hasNewsData: !!newsData,
          newsDataType: typeof newsData,
          isArray: Array.isArray(newsData),
          newsCount: Array.isArray(newsData) ? newsData.length : 0,
          hasPagination: !!paginationData,
          totalPages: paginationData?.total_pages
        });
  
        if (Array.isArray(newsData)) {
          setNews(newsData);
          if (paginationData) {
            setTotalPages(paginationData.total_pages);
          }
          console.log('数据设置成功');
        } else {
          throw new Error('Invalid news data format');
        }
      } else {
        console.warn('API 返回非成功状态:', data);
        throw new Error(data.message || 'API returned error status');
      }
    } catch (err) {
      console.error(`[${new Date().toISOString()}] 请求发生错误:`, {
        browserId: BrowserIdService.getBrowserId(),
        message: err.message,
        code: err.code,
        name: err.name,
        stack: err.stack,
        config: err.config,
        response: {
          status: err.response?.status,
          statusText: err.response?.statusText,
          headers: err.response?.headers,
          data: err.response?.data
        }
      });
      
      if (err.response?.status === 502) {
        console.error('502 错误详细信息:', {
          browserId: BrowserIdService.getBrowserId(),
          requestURL: err.config?.url,
          requestMethod: err.config?.method,
          requestHeaders: err.config?.headers,
          responseType: err.response?.headers?.['content-type'],
          cloudflareRay: err.response?.headers?.['cf-ray'],
          retryAttempt: retryCount + 1,
          totalRetries: MAX_RETRIES
        });
      }
  
      const errorMessage = err.response?.status === 502 
        ? 'Server is temporarily unavailable. Please try again later.'
        : err.message || 'Failed to fetch news';
      
      setError(errorMessage);
      showNotification(errorMessage);
      
      if (retryCount < MAX_RETRIES) {
        const nextRetryCount = retryCount + 1;
        console.log(`准备第 ${nextRetryCount} 次重试`);
        setRetryCount(nextRetryCount);
      } else {
        console.log('已达到最大重试次数，停止重试');
      }
    } finally {
      const requestEndTime = new Date().toISOString();
      console.log(`[${requestEndTime}] 请求结束，当前browser_id: ${BrowserIdService.getBrowserId()}`);
      setLoading(false);
    }
  }, [api, retryCount, showNotification]);

  // 添加刷新函数
  const refreshNews = useCallback(async () => {
    if (isRefreshing) return;
    
    try {
      setIsRefreshing(true);
      setError(null);
      await fetchNews(page);
      setLastUpdated(new Date());
      showNotification('News list has been updated', 'success');
    } catch (error) {
      console.error('Error refreshing news:', error);
      showNotification('Failed to refresh news', 'error');
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchNews, page, showNotification, isRefreshing]);

  // 监听收藏变化
  useEffect(() => {
    const handleFavoriteChange = () => {
      console.log('Favorite changed, refreshing news list...');
      refreshNews();
    };

    window.addEventListener('favoriteChanged', handleFavoriteChange);

    return () => {
      window.removeEventListener('favoriteChanged', handleFavoriteChange);
    };
  }, [refreshNews]);

  useEffect(() => {
    const browserId = BrowserIdService.getBrowserId();
    console.log('Component mounted, current browser_id:', browserId);
    
    const controller = new AbortController();
    fetchNews(page);
    
    return () => {
      controller.abort();
      console.log('Component unmounted, final browser_id:', BrowserIdService.getBrowserId());
    };
  }, [page, fetchNews]);

  const handlePageChange = useCallback((event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  }, []);

  const handleRetry = useCallback(() => {
    setRetryCount(0);
    setError(null);
    fetchNews(page);
  }, [fetchNews, page]);

  if (loading && !news.length) {
    return (
      <div className="news-loading">
        <CircularProgress />
        <Typography variant="body2" sx={{ mt: 2 }}>
          Loading news...
        </Typography>
      </div>
    );
  }


  return (
    <div className="news-container">
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" className="news-title" sx={{ flex: 1 }}>
          Latest News
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="caption" color="text.secondary">
              Last updated: {lastUpdated.toLocaleTimeString()}
            </Typography>
            <Tooltip title="Refresh news">
              <IconButton 
                onClick={refreshNews}
                disabled={isRefreshing}
                size="small"
              >
                <RefreshIcon 
                  sx={{
                    animation: isRefreshing ? 'spin 1s linear infinite' : 'none',
                    '@keyframes spin': {
                      '0%': {
                        transform: 'rotate(0deg)',
                      },
                      '100%': {
                        transform: 'rotate(360deg)',
                      },
                    },
                  }}
                />
              </IconButton>
            </Tooltip>
        </Box>
      </Box>

      {/* 当正在刷新时显示一个小的加载指示器 */}
      {isRefreshing && (
        <LinearProgress 
          sx={{ 
            mb: 2,
            borderRadius: 1,
            height: 2
          }} 
        />
      )}
      
      {error && (
        <Alert
          severity="error"
          className="news-error"
          action={
            <Button color="inherit" size="small" onClick={handleRetry}>
              Retry
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      {(!news || !news.length) && !loading ? (
        <Alert severity="info" className="news-empty">
          No news articles available.
        </Alert>
      ) : (
        <>
          <Grid container spacing={2} className="news-grid">
            {news.map((item) => (
              <Grid item xs={12} key={item.article_id || item.id}>
                <Card 
                  className="news-card"
                  sx={{
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: theme.shadows[4]
                    }
                  }}
                >
                  <CardHeader
                    className="news-card-header"
                    title={item.title}
                    subheader={
                      <div className="news-meta">
                        {item.source && (
                          <span className="news-source">
                            {item.source}
                          </span>
                        )}
                        {item.publish_date && (
                          <span className="news-date">
                            {new Date(item.publish_date).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric'
                            })}
                          </span>
                        )}
                        {item.keyword && (
                          <Chip
                            label={item.keyword}
                            size="small"
                            className="news-keyword-chip"
                          />
                        )}
                      </div>
                    }
                  />
                  {item.image_url && (
                    <div className="news-image-wrapper">
                      <img
                        src={item.image_url}
                        alt={item.title}
                        className="news-image"
                        onError={(e) => {
                          e.target.style.display = 'none';
                        }}
                      />
                    </div>
                  )}
                  <CardContent className="news-card-content">
                    {item.summary && (
                      <Typography variant="body1" className="news-summary">
                        {item.summary}
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions className="news-card-actions">
                    <Button
                      className="news-view-button"
                      size="small"
                      color="primary"
                      variant="outlined"
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Read More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
          
          {totalPages > 1 && (
            <Box className="pagination-container">
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                size="large"
                className="news-pagination"
              />
            </Box>
          )}
        </>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default NewsList;