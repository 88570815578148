// components/similarity.js

export function cosineSimilarity(vecA, vecB) {
    if (vecA.length !== vecB.length) {
        console.error("Vectors must have the same length for cosine similarity.");
        return 0;
    }

    const dotProduct = vecA.reduce((sum, a, idx) => sum + a * vecB[idx], 0);
    const magnitudeA = Math.sqrt(vecA.reduce((sum, val) => sum + val * val, 0));
    const magnitudeB = Math.sqrt(vecB.reduce((sum, val) => sum + val * val, 0));

    if (magnitudeA === 0 || magnitudeB === 0) {
        console.error("One of the vectors has zero magnitude.");
        return 0;
    }

    return dotProduct / (magnitudeA * magnitudeB);
}
