// components/Features.js
import React from "react";
import { 
  BookOpen, 
  Newspaper, 
  Share2, 
  Search, 
  Save, 
  Zap 
} from "lucide-react";

const Features = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold text-center mb-12">Features</h1>
      
      {/* Main Features */}
      <div className="grid md:grid-cols-3 gap-8 mb-16">
        <FeatureCard
          icon={<BookOpen className="w-8 h-8 text-blue-600" />}
          title="Smart Summaries"
          description="Get instant AI-powered summaries that capture the key points from any webpage. Save hours of reading time."
        />
        <FeatureCard
          icon={<Newspaper className="w-8 h-8 text-blue-600" />}
          title="Related Content"
          description="Discover relevant articles and research tailored to your interests. Never miss important connections."
        />
        <FeatureCard
          icon={<Share2 className="w-8 h-8 text-blue-600" />}
          title="Easy Sharing"
          description="Share insights with your team in one click. Perfect for researchers, analysts, and curious minds."
        />
        <FeatureCard
          icon={<Search className="w-8 h-8 text-blue-600" />}
          title="Advanced Search"
          description="Quickly find any summary or article with our powerful search functionality."
        />
        <FeatureCard
          icon={<Save className="w-8 h-8 text-blue-600" />}
          title="Cloud Storage"
          description="All your summaries are securely stored in the cloud, accessible from any device."
        />
        <FeatureCard
          icon={<Zap className="w-8 h-8 text-blue-600" />}
          title="Fast Processing"
          description="Get summaries in seconds with our optimized AI processing pipeline."
        />
      </div>

      {/* Pricing CTA */}
      <div className="text-center">
        <h2 className="text-2xl font-semibold mb-4">Ready to get started?</h2>
        <p className="text-gray-600 mb-6">Choose a plan that works for you.</p>
        <a 
          href="/pricing" 
          className="inline-block bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
        >
          View Pricing
        </a>
      </div>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="p-6 border rounded-lg hover:shadow-lg transition-shadow">
      <div className="mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

export default Features;