// components/indexeddb.js
import { addToSyncQueue } from './syncService';

function openDatabase() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open("EmbeddingDatabase", 6);

        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            const stores = {
                embeddings: "url",
                categoryembeddings: "category",
                queries: "url",
                favorites: "url"
            };
            
            Object.entries(stores).forEach(([storeName, keyPath]) => {
                if (!db.objectStoreNames.contains(storeName)) {
                    db.createObjectStore(storeName, { keyPath });
                }
            });
        };

        request.onsuccess = function(event) {
            resolve(event.target.result);
        };

        request.onerror = function(event) {
            reject("Error opening IndexedDB: " + event.target.errorCode);
        };
    });
}

function clearObjectStore(store) {
    return new Promise((resolve, reject) => {
        const request = store.clear();
        request.onsuccess = () => resolve();
        request.onerror = (event) => reject("Error clearing store: " + event.target.errorCode);
    });
}

export function saveFavoritesToIndexedDB(favorites) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["favorites"], "readwrite");
            const store = transaction.objectStore("favorites");

            await clearObjectStore(store);

            favorites.forEach(fav => {
                store.put(fav);
            });

            transaction.oncomplete = () => {
                addToSyncQueue({
                    type: 'favorites',
                    operation: 'update',
                    data: favorites
                });
                resolve();
            };
            transaction.onerror = (event) => reject("Error saving favorites: " + event.target.errorCode);
        } catch (error) {
            reject("Error saving favorites to IndexedDB: " + error);
        }
    });
}

export function getFavoritesFromIndexedDB() {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(['favorites'], 'readonly');
            const store = transaction.objectStore('favorites');

            const getAllRequest = store.getAll();
            getAllRequest.onsuccess = (event) => resolve(event.target.result || []);
            getAllRequest.onerror = (event) => reject('Error fetching favorites: ' + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}

export function saveEmbeddingToIndexedDB(url, embedding) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["embeddings"], "readwrite");
            const store = transaction.objectStore("embeddings");

            const request = store.put({ url, embedding });
            
            request.onsuccess = () => {
                addToSyncQueue({
                    type: 'embeddings',
                    operation: 'update',
                    data: { url, embedding }
                });
                resolve();
            };
            request.onerror = (event) => reject("Error storing embedding: " + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}

export function saveCategoryEmbeddingToIndexedDB(category, embedding) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["categoryembeddings"], "readwrite");
            const store = transaction.objectStore("categoryembeddings");

            const request = store.put({ category, embedding });
            
            request.onsuccess = () => {
                addToSyncQueue({
                    type: 'categoryembeddings',
                    operation: 'update',
                    data: { category, embedding }
                });
                resolve();
            };
            request.onerror = (event) => reject("Error storing category embedding: " + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}

export function getEmbeddingFromIndexedDB(url) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["embeddings"], "readonly");
            const store = transaction.objectStore("embeddings");

            const getRequest = store.get(url);
            getRequest.onsuccess = (event) => resolve(event.target.result ? event.target.result.embedding : null);
            getRequest.onerror = (event) => reject("Error fetching embedding: " + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}

export function getCategoryEmbeddingFromIndexedDB(category) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["categoryembeddings"], "readonly");
            const store = transaction.objectStore("categoryembeddings");

            const getRequest = store.get(category);
            getRequest.onsuccess = (event) => resolve(event.target.result ? event.target.result.embedding : null);
            getRequest.onerror = (event) => reject("Error fetching category embedding: " + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}

export function getCategoriesFromIndexedDB() {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["categoryembeddings"], "readonly");
            const store = transaction.objectStore("categoryembeddings");

            const getAllRequest = store.getAll();
            getAllRequest.onsuccess = (event) => resolve(event.target.result || []);
            getAllRequest.onerror = (event) => reject("Error fetching categories: " + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}

export function saveQueryToIndexedDB(url, title, category, query) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["queries"], "readwrite");
            const store = transaction.objectStore("queries");

            if (!url || !query) {
                reject("Error: Invalid data for storing in IndexedDB.");
                return;
            }

            const data = { url, title, category, query };
            const request = store.put(data);
            
            request.onsuccess = () => {
                addToSyncQueue({
                    type: 'queries',
                    operation: 'update',
                    data
                });
                resolve();
            };
            request.onerror = (event) => reject("Error storing query: " + event.target.errorCode);
        } catch (error) {
            reject("Error saving query to IndexedDB: " + error);
        }
    });
}

export function getQueryFromIndexedDB(url) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["queries"], "readonly");
            const store = transaction.objectStore("queries");

            const getRequest = store.get(url);
            getRequest.onsuccess = (event) => resolve(event.target.result ? event.target.result.query : null);
            getRequest.onerror = (event) => reject("Error fetching query: " + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}

export function deleteFavoriteFromIndexedDB(url) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["favorites"], "readwrite");
            const store = transaction.objectStore("favorites");

            const request = store.delete(url);
            
            request.onsuccess = () => {
                addToSyncQueue({
                    type: 'favorites',
                    operation: 'delete',
                    data: { url }
                });
                resolve();
            };
            request.onerror = (event) => reject("Error deleting favorite: " + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}