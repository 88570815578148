// components/UrlForm.js
import React from 'react';
import PropTypes from 'prop-types';
import '../css/UrlForm.css';

function UrlForm({ url, setUrl, handleSubmit, loading, className }) {
    const formClassName = `url-form ${className || ''}`.trim();

    return (
        <>
            <p className="url-hint">Paste an article URL to generate its summary.</p>
            <form onSubmit={handleSubmit} className={formClassName}>
                <input
                    type="url"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder="Paste the article URL here"
                    className="url-input"
                    required
                />
                <button 
                    type="submit" 
                    className={`summarize-btn ${loading ? 'loading' : ''}`}
                    title={!url.trim() ? 'Enter a URL to analyze' : ''}
                >
                    {loading ? (
                        <span className="loading-text">
                            <span className="loading-dots">Summarizing</span>
                        </span>
                    ) : (
                        'Summarize'
                    )}
                </button>
            </form>
        </>
    );
}

UrlForm.propTypes = {
    url: PropTypes.string.isRequired,
    setUrl: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    className: PropTypes.string
};

UrlForm.defaultProps = {
    className: ''
};

export default UrlForm;