// src/components/auth/VerifyEmail.js

import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Mail, RefreshCw, CheckCircle, AlertCircle } from 'lucide-react';

export const VerifyEmail = () => {
  const location = useLocation();
  const [isResending, setIsResending] = useState(false);
  const [resendStatus, setResendStatus] = useState('');
  const email = location.state?.email;
  const message = location.state?.message;

  const handleResendVerification = async () => {
    setIsResending(true);
    setResendStatus('');

    try {
      const response = await fetch('/sort/auth/resend-verification/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        setResendStatus('success');
      } else {
        setResendStatus('error');
      }
    } catch (error) {
      setResendStatus('error');
    } finally {
      setIsResending(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-lg shadow-md">
        <div className="text-center">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
            <Mail className="h-6 w-6 text-blue-600" />
          </div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Verify your email
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            We've sent a verification email to<br />
            <span className="font-medium text-gray-900">{email}</span>
          </p>
        </div>

        {message && (
          <div className="bg-blue-50 p-4 rounded-md">
            <p className="text-sm text-blue-700">{message}</p>
          </div>
        )}

        {resendStatus === 'success' && (
          <div className="bg-green-50 p-4 rounded-md flex items-center">
            <CheckCircle className="h-5 w-5 text-green-400 mr-2" />
            <span className="text-green-700">Verification email resent successfully!</span>
          </div>
        )}

        {resendStatus === 'error' && (
          <div className="bg-red-50 p-4 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
            <span className="text-red-700">Failed to resend verification email. Please try again.</span>
          </div>
        )}

        <div className="mt-8 space-y-4">
          <button
            onClick={handleResendVerification}
            disabled={isResending}
            className="w-full flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isResending ? (
              <RefreshCw className="animate-spin h-5 w-5 mr-2" />
            ) : (
              <Mail className="h-5 w-5 mr-2" />
            )}
            {isResending ? 'Resending...' : 'Resend verification email'}
          </button>

          <p className="text-center">
            <Link
              to="/login"
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              Return to sign in
            </Link>
          </p>
        </div>

        <div className="mt-6">
          <p className="text-center text-sm text-gray-500">
            Need help?{' '}
            <a
              href="mailto:support@linksort.ai"
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              Contact support
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;