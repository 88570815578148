// components/ChatPage.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../css/ChatPage.css';
import ErrorBoundary from './ErrorBoundary';

const ChatPage = () => {
    //console.log('ChatPage component rendered again');

    const { url } = useParams();
    const [question, setQuestion] = useState('');
    const [conversation, setConversation] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log('ChatPage component mounted'); // 组件挂载日志
        console.log('Initial URL:', decodeURIComponent(url));
    }, [url]);

    useEffect(() => {
        console.log('Conversation updated:', conversation); // 对话更新日志
    }, [conversation]);

    const handleAskQuestion = async () => {
        console.log('handleAskQuestion called with question:', question);
        if (!question) {
            console.log('No question provided, returning early');
            return;
        }
        setLoading(true);
        try {
            console.log('Sending request to backend');
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/sort/askQuestion/`, {
                url: decodeURIComponent(url),
                question,
            }, {
                withCredentials: true,
            });
            console.log('Received response from backend:', response.data);
            const newPair = {
                question,
                answer: response.data.answer
            };
            console.log('New conversation pair:', newPair);
            setConversation(prevConversation => {
                const updatedConversation = [...prevConversation, newPair];
                console.log('Updated conversation state:', updatedConversation);
                return updatedConversation;
            });
            setQuestion('');  // Clear the input field after the question is sent
        } catch (error) {
            console.error('Error getting answer:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ErrorBoundary>
            {/* 现有的JSX */}
        
            <div className="chat-page">
                <h2>Chat with the Article</h2>

                {/* Open article in a new tab */}
                <div className="article-link-container">
                    <a href={decodeURIComponent(url)} target="_blank" rel="noopener noreferrer">
                        Click here to open the article in a new tab
                    </a>
                </div>

                {/* Conversation history */}
                <div className="conversation-section">
                    {conversation.length === 0 ? (
                        <p>No questions asked yet. Start the conversation!</p>
                    ) : (
                        conversation.map((qa, index) => (
                            <div key={index} className="conversation-group">
                                {console.log('Rendering conversation item:', qa)}
                                <div className="conversation-card user">
                                    <p><strong>You:</strong> {qa.question}</p>
                                </div>
                                <div className="conversation-card system">
                                    <p><strong>Answer:</strong> {qa.answer}</p>
                                </div>
                            </div>
                        ))
                    )}
                </div>

                {/* Input section */}
                <div className="input-section">
                    <input
                        type="text"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        placeholder="Ask something about the article..."
                        className="question-input"
                    />
                    <button onClick={handleAskQuestion} disabled={loading}>
                        {loading ? 'Processing...' : 'Ask'}
                    </button>
                </div>
            </div>
        </ErrorBoundary>
    );
};

export default ChatPage;
