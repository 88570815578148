// src/components/auth/AccountSettings.js

import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Mail, Lock, LogOut, AlertCircle, Loader2, RefreshCw } from 'lucide-react';
import { useAuth } from './AuthContext';

// Section Component
const SettingsSection = ({ title, description, icon: Icon, children }) => (
  <div className="p-6">
    <div className="flex items-center justify-between">
      <div>
        <h3 className="text-lg font-medium text-gray-900">{title}</h3>
        <p className="mt-1 text-sm text-gray-500">{description}</p>
      </div>
      <Icon className="h-5 w-5 text-gray-400" />
    </div>
    <div className="mt-4">{children}</div>
  </div>
);

// Action Button Component
const ActionButton = ({ 
  onClick, 
  disabled, 
  icon: Icon, 
  loadingText, 
  text, 
  isLoading, 
  variant = "default" 
}) => {
  const baseClasses = "w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed";
  const variants = {
    default: "text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500",
    danger: "text-white bg-red-600 hover:bg-red-700 focus:ring-red-500"
  };

  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`${baseClasses} ${variants[variant]}`}
    >
      {isLoading ? (
        <>
          <Loader2 className="h-5 w-5 mr-2 animate-spin" />
          {loadingText}
        </>
      ) : (
        <>
          <Icon className="h-5 w-5 mr-2" />
          {text}
        </>
      )}
    </button>
  );
};

export const AccountSettings = () => {
  const navigate = useNavigate();
  const { user, logout, resendVerification } = useAuth();
  const [error, setError] = React.useState('');
  const [isSigningOut, setIsSigningOut] = React.useState(false);
  const [isResendingVerification, setIsResendingVerification] = React.useState(false);
  const [verificationSent, setVerificationSent] = React.useState(false);

  const handleSignOut = async () => {
    try {
      setIsSigningOut(true);
      setError('');
      await logout();
      navigate('/');
    } catch (err) {
      setError('Failed to sign out. Please try again.');
      console.error('Logout error:', err);
    } finally {
      setIsSigningOut(false);
    }
  };

  const handleResendVerification = async () => {
    try {
      setIsResendingVerification(true);
      setError('');
      await resendVerification();
      setVerificationSent(true);
    } catch (err) {
      setError('Failed to resend verification email. Please try again.');
      console.error('Verification error:', err);
    } finally {
      setIsResendingVerification(false);
    }
  };

  // Loading state is handled by AuthProvider
  if (!user) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <Loader2 className="h-8 w-8 text-blue-600 animate-spin" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full mx-auto space-y-8">
        <div>
          <h2 className="text-3xl font-bold text-center text-gray-900">
            Account Settings
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Manage your account preferences and settings
          </p>
        </div>

        {error && (
          <div className="bg-red-50 p-4 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
            <span className="text-red-700">{error}</span>
          </div>
        )}

        {verificationSent && (
          <div className="bg-green-50 p-4 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 text-green-400 mr-2" />
            <span className="text-green-700">Verification email sent successfully!</span>
          </div>
        )}

        <div className="bg-white shadow rounded-lg divide-y divide-gray-200">
          <SettingsSection
            title="Email Address"
            description={
              <>
                {user.email}
                {user.is_verified ? (
                  <span className="ml-2 text-green-600">(Verified)</span>
                ) : (
                  <span className="ml-2 text-yellow-600">(Not Verified)</span>
                )}
              </>
            }
            icon={Mail}
          >
            {!user.is_verified && (
              <ActionButton
                onClick={handleResendVerification}
                disabled={isResendingVerification}
                icon={RefreshCw}
                text="Resend Verification Email"
                loadingText="Sending..."
                isLoading={isResendingVerification}
              />
            )}
            <Link
              to="/accounts/email/"
              className="mt-2 w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Manage Emails
            </Link>
          </SettingsSection>

          <SettingsSection
            title="Password"
            description="Update your password"
            icon={Lock}
          >
            <Link
              to="/accounts/password/change/"
              className="w-full inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Change Password
            </Link>
          </SettingsSection>

          <SettingsSection
            title="Sign Out"
            description="Sign out of your account"
            icon={LogOut}
          >
            <ActionButton
              onClick={handleSignOut}
              disabled={isSigningOut}
              icon={LogOut}
              text="Sign Out"
              loadingText="Signing Out..."
              isLoading={isSigningOut}
              variant="danger"
            />
          </SettingsSection>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;