// components/HelpCenter.js
import React from "react";
import { Link } from "react-router-dom";
import { Search } from "lucide-react";

const HelpCenter = () => {
  const faqs = [
    {
      question: "How does LinkSort.ai work?",
      answer: "LinkSort.ai uses OpenAI's GPT model to analyze web pages and generate concise summaries. Simply paste any URL, and our system will extract the key points and relevant information."
    },
    {
      question: "Do I need to create an account?",
      answer: "While you can try our demo without an account, creating a free account allows you to save your summaries, access them across devices, and unlock additional features."
    },
    {
      question: "What types of content can I summarize?",
      answer: "You can summarize articles, blog posts, news stories, research papers, and most text-based web content. Some content like private pages or pages requiring login may not be accessible."
    },
    {
      question: "How accurate are the summaries?",
      answer: "We use OpenAI's GPT model to generate summaries. While the model is very capable at extracting and condensing information, we recommend reviewing the original content for critical information to ensure nothing important is missed."
    },
    {
      question: "Can I save and organize my summaries?",
      answer: "Yes! With a free account, you can save all your summaries, organize them into folders, and access them anytime from any device."
    }
  ];

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold text-center mb-8">Help Center</h1>
      
      {/* Search Bar */}
      <div className="relative mb-12">
        <input
          type="text"
          placeholder="Search help articles..."
          className="w-full p-4 pl-12 border rounded-lg"
        />
        <Search className="absolute left-4 top-4 text-gray-400" />
      </div>

      {/* Quick Links */}
      <div className="grid md:grid-cols-3 gap-4 mb-12">
        <QuickLink
          title="Getting Started"
          description="Learn the basics of using LinkSort.ai"
          href="/help/getting-started"
        />
        <QuickLink
          title="Account & Billing"
          description="Manage your account and subscription"
          href="/help/account"
        />
        <QuickLink
          title="API Documentation"
          description="Integrate LinkSort.ai into your workflow"
          href="/help/api"
        />
      </div>

      {/* FAQs */}
      <div className="mb-12">
        <h2 className="text-2xl font-semibold mb-6">Frequently Asked Questions</h2>
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <div key={index} className="border-b pb-6">
              <h3 className="text-lg font-medium mb-2">{faq.question}</h3>
              <p className="text-gray-600">{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Contact Support */}
      <div className="text-center">
        <h2 className="text-2xl font-semibold mb-4">Still need help?</h2>
        <p className="text-gray-600 mb-6">Our support team is here to assist you.</p>
        <Link 
          to="/contact" 
          className="inline-block bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
        >
          Contact Support
        </Link>
      </div>
    </div>
  );
};

const QuickLink = ({ title, description, href }) => {
  return (
    <Link 
      to={href}
      className="block p-6 border rounded-lg hover:shadow-lg transition-shadow"
    >
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </Link>
  );
};

export default HelpCenter;