// components/MainLayout.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import UrlForm from './UrlForm';
import FavoritesList from './FavoritesList';
import NewsList from './NewsList';

const MainLayout = ({
    url,
    setUrl,
    handleSubmit,
    loading,
    favorites,
    setFavorites,        
    setFilteredFavorites, 
    categories,
    filterFavoritesByCategory,
    handleCompareEmbeddings
}) => {
    const [activeCategory, setActiveCategory] = useState(null);
    const handleSubmitRef = useRef(handleSubmit);

    // 更新 handleSubmit ref
    useEffect(() => {
        handleSubmitRef.current = handleSubmit;
    }, [handleSubmit]);

    // 改进的 URL 处理逻辑
    const handlePendingUrl = useCallback(() => {
        const pendingUrl = sessionStorage.getItem('pendingUrl');
        if (pendingUrl && handleSubmitRef.current) {
            setUrl(pendingUrl);
            sessionStorage.removeItem('pendingUrl');
            
            // 添加延迟确保状态更新完成
            setTimeout(() => {
                const syntheticEvent = {
                    preventDefault: () => {},
                };
                handleSubmitRef.current(syntheticEvent);
            }, 100);
        }
    }, [setUrl]); // 移除 handleSubmit 依赖

    // 使用 ref 来避免依赖循环
    useEffect(() => {
        if (handleSubmitRef.current) {
            handlePendingUrl();
        }
    }, [handlePendingUrl]);

    const handleCategoryClick = (cat) => {
        if (activeCategory === cat) {
            clearCategorySelection();
        } else {
            setActiveCategory(cat);
            filterFavoritesByCategory(cat);
        }
    };

    const clearCategorySelection = () => {
        setActiveCategory(null); 
        filterFavoritesByCategory(null);
    };

    const pastelColors = [
        '#FFE7C1', // Soft orange
        '#C8E1FF', // Soft blue
        '#FFEDDE', // Soft pink
        '#D7F3E3', // Soft green
        '#FCE2FF', // Soft purple
        '#FFF5C1', // Soft yellow
        '#D7CFFF', // Soft violet
    ];

    const categoryColorMap = {};
    categories.forEach((category, index) => {
        categoryColorMap[category] = pastelColors[index % pastelColors.length];
    });

    const getCategoryColor = (category) => {
        return categoryColorMap[category] || '#FFFFFF';
    };

    // 分类列表的悬停效果
    useEffect(() => {
        const categoryItems = document.querySelectorAll('.category-list li');
        const listeners = new Map();

        categoryItems.forEach(item => {
            const color = item.getAttribute('data-color');
            const handleEnter = () => {
                item.style.backgroundColor = color;
            };
            const handleLeave = () => {
                if (!item.classList.contains('active')) {
                    item.style.backgroundColor = '';
                }
            };

            listeners.set(item, { handleEnter, handleLeave });
            item.addEventListener('mouseenter', handleEnter);
            item.addEventListener('mouseleave', handleLeave);
        });

        // 改进的清理函数
        return () => {
            categoryItems.forEach(item => {
                const { handleEnter, handleLeave } = listeners.get(item) || {};
                if (handleEnter && handleLeave) {
                    item.removeEventListener('mouseenter', handleEnter);
                    item.removeEventListener('mouseleave', handleLeave);
                }
            });
        };
    }, [categories, activeCategory]);

    return (
        <div className="main-layout">
            <div className="url-summary-section">
                <UrlForm 
                    url={url} 
                    setUrl={setUrl} 
                    handleSubmit={handleSubmit} 
                    loading={loading} 
                    className="url-form"
                />
                {loading && (
                    <p className="loading-message text-center mt-4 text-gray-600">
                        Processing your request, please wait...
                    </p>
                )}
            </div>

            <div className="content-section three-column">
                <div className="category-section">
                    <h2 className="section-title">Categories</h2>
                    <ul className="category-list">
                        {categories.map((cat, index) => (
                            <li
                                key={index}
                                data-color={getCategoryColor(cat)}
                                onClick={() => handleCategoryClick(cat)}
                                className={`category-item ${activeCategory === cat ? 'active' : ''}`}
                                style={{
                                    backgroundColor: activeCategory === cat ? getCategoryColor(cat) : '',
                                }}
                            >
                                <span className="category-text">{cat}</span>
                                {activeCategory === cat && (
                                    <button 
                                        className="cancel-icon" 
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            clearCategorySelection();
                                        }}
                                        aria-label="Clear category selection"
                                    >
                                        ✖
                                    </button>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="favorites-section">
                    <h2 className="section-title">Saved Articles</h2>
                    <FavoritesList 
                        favorites={favorites} 
                        setFavorites={setFavorites}
                        setFilteredFavorites={setFilteredFavorites} 
                        getCategoryColor={getCategoryColor}
                    />
                </div>

                <div className="news-section">
                    <h2 className="section-title">Latest News</h2>
                    <NewsList />
                </div>
            </div>
        </div>
    );
};

export default MainLayout;