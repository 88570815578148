// src/components/AuthContext.js

import React, { createContext, useContext, useEffect, useState, useCallback, useRef } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

const AuthContext = createContext(null);
const MAX_RETRY_ATTEMPTS = 3;

// Custom error class for registration errors
class RegistrationError extends Error {
  constructor(message, errors = null) {
    super(message);
    this.name = 'RegistrationError';
    this.errors = errors;
  }
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [csrfToken, setCsrfToken] = useState(null);
  
  // Add refs to prevent unnecessary re-renders and api calls
  const initializeRef = useRef(false);
  const authCheckTimeoutRef = useRef(null);

  // 配置 axios 默认值
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.timeout = 10000;
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    
    if (process.env.REACT_APP_API_BASE_URL) {
      axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
    }
  }, []);

  // 刷新 CSRF Token
  const refreshCSRFToken = useCallback(async () => {
    try {
      const response = await axios.get('/sort/get_csrf/', {
        withCredentials: true,
        headers: {
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      });
      
      const token = response.data.csrfToken;
      if (token) {
        setCsrfToken(token);
        axios.defaults.headers.common['X-CSRFToken'] = token;
      }
      
      console.log('✅ CSRF token refreshed');
      return response;
    } catch (err) {
      console.error('❌ Failed to refresh CSRF token:', err);
      throw err;
    }
  }, []);

  // 统一的 API 请求处理方法
  const apiRequest = useCallback(async (config, retryCount = 0) => {
    try {
      // 确保先刷新 CSRF token
      if (!csrfToken) {
        await refreshCSRFToken();
      }

      const defaultHeaders = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json'
      };

      if (config.data instanceof FormData) {
        delete defaultHeaders['Content-Type'];
      }

      const requestConfig = {
        ...config,
        headers: {
          ...defaultHeaders,
          ...config.headers,
          'X-CSRFToken': csrfToken
        },
        withCredentials: true
      };

      console.log('🚀 Request:', {
        url: requestConfig.url,
        method: requestConfig.method,
        headers: requestConfig.headers,
        data: requestConfig.data instanceof FormData ? '[FormData]' : requestConfig.data
      });

      const response = await axios(requestConfig);

      console.log('✅ Response:', {
        url: response.config.url,
        status: response.status,
        data: response.data
      });

      return response;
    } catch (err) {
      const status = err.response?.status;
      const errorMessage = err.response?.data?.message 
        || err.response?.data?.error 
        || err.response?.data?.detail 
        || err.message;

      console.error(`❌ ${config.method?.toUpperCase() || 'API'} error:`, {
        url: config.url,
        status: status,
        data: err.response?.data,
        message: errorMessage,
        retryCount: retryCount
      });

      if ((status === 403 || errorMessage.includes('CSRF')) && retryCount < MAX_RETRY_ATTEMPTS) {
        console.log(`🔄 Retrying request (${retryCount + 1}/${MAX_RETRY_ATTEMPTS})`);
        await refreshCSRFToken();
        return apiRequest(config, retryCount + 1);
      }

      if (status === 401) {
        setUser(null);
      }

      throw err;
    }
  }, [csrfToken, refreshCSRFToken]);

  // 清理用户认证相关的所有存储
  const clearAuthStorage = useCallback(() => {
    // 清除认证相关的所有cookie
    Cookies.remove('browser_id');
    Cookies.remove('sessionid');
    
    // 清除本地存储
    localStorage.removeItem('browser_id');
    localStorage.removeItem('isAuthenticated');
    sessionStorage.removeItem('browser_id');
    
    console.log('🧹 Cleared all auth-related storage');
  }, []);

  // 设置认证状态
  const setAuthenticationState = useCallback((userData) => {
    if (userData) {
      // 用户已认证，清除匿名用户相关的存储
      clearAuthStorage();
      // 设置认证标记
      localStorage.setItem('isAuthenticated', 'true');
      setUser(userData);
    } else {
      // 用户未认证
      clearAuthStorage();
      setUser(null);
    }
  }, [clearAuthStorage]);

  // 更新检查认证状态的方法
  const checkAuth = useCallback(async () => {
    try {
      const response = await apiRequest({
        method: 'get',
        url: '/sort/auth/user-info/',
        validateStatus: (status) => {
          return status === 200 || status === 403;
        }
      });
      
      if (response.status === 200 && response.data.data) {
        setAuthenticationState(response.data.data);
        return true;
      } else {
        setAuthenticationState(null);
        return false;
      }
    } catch (err) {
      setAuthenticationState(null);
      console.warn('⚠️ Auth check failed:', err.message);
      return false;
    }
  }, [apiRequest, setAuthenticationState]);

  // Initialize effect - 只展示修改的相关部分
  useEffect(() => {
    const initialize = async () => {
      if (initializeRef.current) return;
      initializeRef.current = true;

      try {
        setLoading(true);
        await refreshCSRFToken();
        await checkAuth();
        setIsInitialized(true);
      } catch (err) {
        console.error('❌ Failed to initialize:', err);
        setError('Failed to initialize application');
      } finally {
        setLoading(false);
      }
    };

    // 创建一个标志来追踪当前的 effect 实例
    let isCurrentEffect = true;
    
    initialize();

    // 清理函数
    return () => {
      isCurrentEffect = false;
      if (authCheckTimeoutRef.current) {
        clearTimeout(authCheckTimeoutRef.current);
        // 只在当前 effect 实例中清除 timeout ref
        if (isCurrentEffect) {
          authCheckTimeoutRef.current = null;
        }
      }
    };
  }, [checkAuth, refreshCSRFToken]);

  // Axios 拦截器 - 移除不必要的日志记录，因为已经在 apiRequest 中处理
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      config => {
        if (csrfToken) {
          config.headers['X-CSRFToken'] = csrfToken;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      response => response,
      error => Promise.reject(error)
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [csrfToken]);

  // 更新登录方法
  const login = useCallback(async (credentials) => {
    try {
      await refreshCSRFToken();
      
      const formData = new FormData();
      formData.append('login', credentials.email);
      formData.append('password', credentials.password);
      formData.append('remember', credentials.remember ? 'on' : '');

      const response = await apiRequest({
        method: 'post',
        url: '/sort/login/',
        data: formData,
        headers: {
          'Accept': 'application/json',
        }
      });

      if (response.status === 200) {
        // 清除匿名用户相关的存储
        clearAuthStorage();
        // 设置认证状态
        localStorage.setItem('isAuthenticated', 'true');
        // 重新获取用户信息
        await checkAuth();
        
        console.log('🔑 Login successful - cleared anonymous user data');
        
        return {
          success: true,
          location: response.data.location || '/app',
          data: response.data
        };
      }

      throw new Error(response.data.error || 'Invalid response from server');
    } catch (err) {
      console.error('❌ Login failed:', err);
      if (err.response) {
        const errorMessage = err.response.data.error || 
                           err.response.data.message || 
                           'Authentication failed';
        throw new Error(errorMessage);
      }
      throw err;
    }
  }, [apiRequest, checkAuth, refreshCSRFToken, clearAuthStorage]);

  // 注册
  const register = useCallback(async (userData) => {
    try {
      await refreshCSRFToken();
      
      const formData = new FormData();
      formData.append('email', userData.email);
      formData.append('password1', userData.password1);
      formData.append('password2', userData.password2);

      const response = await apiRequest({
        method: 'post',
        url: '/sort/signup/',
        data: formData,
        headers: {
          'Accept': 'application/json',
        }
      });

      if (response.status === 200) {
        const redirectLocation = response.data.location || '/app';
        
        if (response.data.data) {
          await checkAuth();
        }
        
        return {
          success: true,
          location: redirectLocation,
          message: response.data.message || 'Registration successful',
          data: response.data.data || null
        };
      }

      throw new Error('Unexpected response from server');
    } catch (err) {
      console.error('Registration error details:', {
        error: err,
        response: err.response?.data,
        status: err.response?.status
      });

      if (err.response?.data) {
        const errorData = err.response.data;
        
        if (errorData.errors) {
          throw new RegistrationError(
            errorData.message || 'Registration failed',
            errorData.errors
          );
        }
        
        if (errorData.message) {
          throw new Error(errorData.message);
        }
        
        if (errorData.error) {
          throw new Error(errorData.error);
        }
      }
      
      if (err instanceof RegistrationError) {
        throw err;
      }
      
      throw new Error(err.message || 'An error occurred during registration');
    }
  }, [apiRequest, checkAuth, refreshCSRFToken]);

  // 更新登出方法
  const logout = useCallback(async () => {
    try {
      await apiRequest({
        method: 'post',
        url: '/sort/logout/'
      });
    } catch (err) {
      console.error('Logout error:', err);
    } finally {
      setAuthenticationState(null);
      setCsrfToken(null);
      initializeRef.current = false;
      console.log('👋 Logout complete - cleared all auth data');
    }
  }, [apiRequest, setAuthenticationState]);

  // 重发验证邮件
  const resendVerification = useCallback(async () => {
    try {
      const response = await apiRequest({
        method: 'post',
        url: '/sort/auth/resend-verification/'
      });
      return response.data;
    } catch (err) {
      throw new Error('Failed to resend verification: ' + err.message);
    }
  }, [apiRequest]);

  const value = {
    user,
    loading,
    error,
    login,
    register,
    logout,
    resendVerification,
    checkAuth,
    isAuthenticated: !!user,
    isVerified: user?.is_verified || false,
    isInitialized,
    clearAuthStorage
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const ProtectedRoute = ({ children }) => {
  const { user, loading, isInitialized } = useAuth();
  const location = useLocation();

  if (loading || !isInitialized) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="spinner" />
      </div>
    );
  }

  if (!user) {
    console.log('🚫 Access denied - redirecting to login');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default AuthContext;