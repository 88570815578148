// src/components/auth/EmailConfirmation.js
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

export const EmailConfirmation = () => {
    const { key } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const confirmEmail = async () => {
            try {
                // 显示加载状态
                console.log('Confirming email...');
                
                // 发送确认请求到后端
                const response = await axios.get(`/accounts/confirm-email/${key}/`, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                });

                console.log('Email confirmation response:', response);

                // 根据响应重定向
                if (response.data.success) {
                    navigate('/email-verified-success');
                } else {
                    navigate('/email-verified-error');
                }
            } catch (error) {
                console.error('Email confirmation error:', error);
                navigate('/email-verified-error');
            }
        };

        confirmEmail();
    }, [key, navigate]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
            <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-md">
                <div className="text-center">
                    <h2 className="text-2xl font-semibold text-gray-900 mb-2">
                        Verifying Your Email
                    </h2>
                    <p className="text-gray-600">
                        Please wait while we verify your email address...
                    </p>
                    {/* 可以添加一个加载动画 */}
                    <div className="mt-4">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailConfirmation;