// components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './auth/AuthContext';
import UserMenu from './auth/UserMenu';
import '../css/Header.css';

function Header({ title }) {
    const { user } = useAuth();

    return (
        <header className="app-header">
            <div className="header-content flex justify-between items-center max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <Link to="/" className="logo-link flex items-center">
                    <img 
                        src="/linksort_star.svg" 
                        alt="LinkSummarizer Logo" 
                        className="app-logo h-8 w-auto"
                    />
                    <h1 className="app-title ml-2">{title}</h1>
                </Link>
                
                {user && <UserMenu />}
            </div>
        </header>
    );
}

export default Header;