// components/generateQuery.js
import axios from 'axios';
import Cookies from 'js-cookie';
import { saveQueryToIndexedDB } from './indexeddb';  // Updated function

export const generateQuery = async (url, title, category) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const csrfToken = Cookies.get('csrftoken');

    try {
        const response = await axios.post(
            `${apiUrl}/sort/generateQuery/`,
            { title: title, category: category },
            {
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            }
        );
        
        const query = response.data.query;
        console.log(`generated query: ${query}`);

        // Save query to IndexedDB with the url
        await saveQueryToIndexedDB(url, title, category, query);
        
        return query;
    } catch (error) {
        console.error('Error generating query', error);
        return null;
    }
};
