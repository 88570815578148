// src/components/auth/LoginForm.js

import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Mail, Lock, Eye, EyeOff, AlertCircle, Loader2 } from 'lucide-react';
import { useAuth } from './AuthContext';
import Cookies from 'js-cookie';

// FormInput component remains unchanged
const FormInput = ({
  icon: Icon,
  register,
  name,
  validation,
  type,
  placeholder,
  error,
  showPasswordToggle,
  onTogglePassword,
  showPassword
}) => (
  <div className="relative">
    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
      <Icon className="h-5 w-5 text-gray-400" />
    </div>
    <input
      {...register(name, validation)}
      type={type}
      className={`appearance-none relative block w-full px-3 py-2 pl-10 ${
        showPasswordToggle ? 'pr-10' : ''
      } border ${
        error ? 'border-red-300' : 'border-gray-300'
      } rounded-md placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm`}
      placeholder={placeholder}
    />
    {showPasswordToggle && (
      <button
        type="button"
        className="absolute inset-y-0 right-0 pr-3 flex items-center"
        onClick={onTogglePassword}
      >
        {showPassword ? (
          <EyeOff className="h-5 w-5 text-gray-400" />
        ) : (
          <Eye className="h-5 w-5 text-gray-400" />
        )}
      </button>
    )}
    {error && (
      <p className="mt-1 text-xs text-red-600">{error.message}</p>
    )}
  </div>
);

export const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const [showPassword, setShowPassword] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState('');
  const [fieldErrors, setFieldErrors] = React.useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      remember: false
    }
  });

  const handleServerErrors = (error) => {
    const errorData = error.response?.data;
    
    if (errorData?.errors) {
      Object.entries(errorData.errors).forEach(([field, messages]) => {
        if (Array.isArray(messages) && messages.length > 0) {
          setError(field === 'login' ? 'email' : field, {
            type: 'server',
            message: messages[0]
          });
        }
      });
    }
    
    const errorMessage = errorData?.message || 
                        errorData?.error || 
                        error.message || 
                        'Login failed. Please try again.';
    setServerError(errorMessage);
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setServerError('');
    setFieldErrors({});

    try {
      const response = await login({
        email: data.email,
        password: data.password,
        remember: data.remember
      });

      // 登录成功
      if (response.success) {
        // 清除browser_id相关的所有存储
        Cookies.remove('browser_id');
        localStorage.removeItem('browser_id');
        sessionStorage.removeItem('browser_id');
        
        // 设置认证状态
        localStorage.setItem('isAuthenticated', 'true');
        
        // 记录日志
        console.log('Successfully logged in and cleared browser_id');
        
        // 延迟一小段时间确保cookie被清除
        await new Promise(resolve => setTimeout(resolve, 100));
        
        // 重新加载页面以确保所有状态都被重置
        const redirectTo = response.location || location.state?.from?.pathname || '/app';
        window.location.href = redirectTo;
        return;
      }

      setServerError('Login failed. Please try again.');
      
    } catch (error) {
      console.error('Login error:', error);
      
      if (error.response?.data?.errors) {
        const { errors } = error.response.data;
        Object.entries(errors).forEach(([field, messages]) => {
          if (Array.isArray(messages) && messages.length > 0) {
            setError(field === 'login' ? 'email' : field, {
              type: 'server',
              message: messages[0]
            });
          }
        });
      }
      
      setServerError(
        error.response?.data?.message || 
        error.message || 
        'An error occurred during login'
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  
  const getFieldError = (fieldName) => {
    return errors[fieldName] || fieldErrors[fieldName];
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-lg shadow-md">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            <Link to="/register" className="font-medium text-blue-600 hover:text-blue-500">
              create a new account
            </Link>
          </p>
        </div>

        {serverError && (
          <div className="bg-red-50 p-4 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
            <span className="text-red-700">{serverError}</span>
          </div>
        )}

        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="space-y-4">
            <FormInput
              icon={Mail}
              register={register}
              name="email"
              validation={{
                required: 'Email is required',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Please enter a valid email'
                }
              }}
              type="email"
              placeholder="Email address"
              error={getFieldError('email')}
            />

            <FormInput
              icon={Lock}
              register={register}
              name="password"
              validation={{
                required: 'Password is required',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters'
                }
              }}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              error={getFieldError('password')}
              showPasswordToggle
              onTogglePassword={() => setShowPassword(!showPassword)}
              showPassword={showPassword}
            />
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                {...register('remember')}
                type="checkbox"
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                id="remember-me"
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                Remember me
              </label>
            </div>

            <div className="text-sm">
              <Link 
                to="/accounts/password/reset/" 
                className="font-medium text-blue-600 hover:text-blue-500"
              >
                Forgot your password?
              </Link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {isSubmitting ? (
                <>
                  <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Signing in...
                </>
              ) : (
                'Sign in'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;