// components/FavoritesList.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteFavoriteFromIndexedDB, saveFavoritesToIndexedDB } from './indexeddb';
import '../css/Favorites.css';

function FavoritesList({ favorites, setFavorites, setFilteredFavorites, getCategoryColor }) {
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    // 实时过滤收藏列表
    const filteredFavorites = favorites.filter(fav =>
        fav.summary.toLowerCase().includes(searchTerm.toLowerCase()) || 
        fav.url.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // 处理删除收藏
    const handleDeleteFavorite = async (index) => {
        try {
            // 获取要删除的收藏项
            const favoriteToDelete = favorites[index];

            // 从 IndexedDB 中删除
            await deleteFavoriteFromIndexedDB(favoriteToDelete.url);
            
            // 更新前端状态
            const newFavorites = favorites.filter((_, i) => i !== index);
            setFavorites(newFavorites);
            setFilteredFavorites(newFavorites);
        
            // 保存更新后的收藏列表到 IndexedDB
            await saveFavoritesToIndexedDB(newFavorites);

            // 触发收藏变化事件
            window.dispatchEvent(new CustomEvent('favoriteChanged', {
                detail: {
                    type: 'delete',
                    url: favoriteToDelete.url
                }
            }));
            
        } catch (error) {
            console.error('Error deleting favorite:', error);
            // 可以添加错误提示
            alert('Failed to delete favorite. Please try again.');
        }
    };

    return (
        <div className="favorites-list" style={{ minHeight: '400px' }}>
            <h2>Favorites:</h2>

            {favorites.length > 0 && (
                <input
                    type="text"
                    placeholder="Search summaries..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
            )}

            {favorites.length === 0 ? (
                <div className="no-favorites">
                    <i className="fas fa-arrow-up"></i>
                    <p>You haven't summarized any articles yet! Paste a link above to get started.</p>
                </div>
            ) : (
                filteredFavorites.length === 0 ? (
                    <p>No matching favorites found.</p>
                ) : (
                    <ul>
                        {filteredFavorites.map((fav, index) => {
                            const paragraphs = fav.summary.split('\n\n');
                            return (
                                <li 
                                    key={index} 
                                    style={{ 
                                        borderColor: getCategoryColor(fav.category), 
                                        borderWidth: '4px', 
                                        borderStyle: 'solid', 
                                        borderRadius: '12px', 
                                        marginBottom: '10px', 
                                        padding: '15px' 
                                    }}
                                >
                                    <a href={fav.url} target="_blank" rel="noopener noreferrer">{fav.url}</a>

                                    {fav.title && <h2>{fav.title}</h2>}
                                    
                                    {paragraphs.map((para, idx) => (
                                        <p key={idx}>{para}</p>
                                    ))}

                                    {Array.isArray(fav.keyPoints) && fav.keyPoints.length > 0 && (
                                        <div className="key-points-section">
                                            <h3>Key Points:</h3>
                                            <ul className="key-points-list">
                                                {fav.keyPoints.map((point, kpIndex) => (
                                                    <li key={kpIndex} className="key-point-item">
                                                        <div className="key-point-index">{kpIndex + 1}</div>
                                                        <div className="key-point-content">{point}</div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}

                                    <p><strong>Category: {fav.category}</strong></p>
                                    <button 
                                        className="chat-btn" 
                                        onClick={() => navigate(`/chat/${encodeURIComponent(fav.url)}`)}
                                    >
                                        Chat with Article
                                    </button>
                                    <button 
                                        className="news-btn" 
                                        onClick={() => navigate(`/related-news/${encodeURIComponent(fav.url)}`)}
                                    >
                                        Related News
                                    </button>
                                    <button onClick={() => handleDeleteFavorite(index)}>Delete</button>
                                </li>
                            );
                        })}
                    </ul>
                )
            )}
        </div>
    );
}

export default FavoritesList;