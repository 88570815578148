// components/RelatedNewsPage.js
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { getFavoritesFromIndexedDB } from './indexeddb';
import '../css/relatedNewsPage.css';

// 新闻卡片组件
const NewsCard = React.memo(({ news, articleContent }) => {
  const [imageError, setImageError] = useState(false);

  return (
    <li className="news-item">
      {news.socialimage && !imageError && (
        <div className="image-container">
          <img
            src={news.socialimage}
            alt={news.title}
            className="news-image"
            loading="lazy"
            onError={() => setImageError(true)}
          />
        </div>
      )}
      <div className="news-content">
        <a
          href={news.url}
          target="_blank"
          rel="noopener noreferrer"
          className="news-title-link"
        >
          {news.title}
        </a>
        {news.seendate && (
          <p className="news-date">
            Published on: {formatDate(news.seendate)}
          </p>
        )}
        <ArticleContent content={articleContent} />
        <div className="news-meta">
          <span>Source: {news.domain}</span>
        </div>
      </div>
    </li>
  );
});

// 文章内容组件
const ArticleContent = React.memo(({ content }) => {
  if (!content) {
    return (
      <div className="loading-content">
        <div className="spinner"></div>
        <p>Loading article content...</p>
      </div>
    );
  }

  return (
    <>
      <div className="summary-section">
        <h3>Summary:</h3>
        <p>{content.summary}</p>
      </div>
      {content.keywords?.length > 0 && (
        <div className="keywords-section">
          <h4>Key Points:</h4>
          <div className="keywords">
            {content.keywords.map((keyword, idx) => (
              <span key={idx} className="keyword-tag">
                {keyword}
              </span>
            ))}
          </div>
        </div>
      )}
    </>
  );
});

// 错误提示组件
const ErrorMessage = ({ message, onRetry }) => (
  <div className="error-container">
    <p className="error-message">{message}</p>
    <button onClick={onRetry} className="retry-button">
      Retry
    </button>
  </div>
);

// Loading 组件
const LoadingSpinner = () => (
  <div className="spinner-container">
    <div className="spinner"></div>
    <p>Loading related news...</p>
  </div>
);

// 日期格式化函数
const formatDate = (seendate) => {
  try {
    const date = new Date(
      `${seendate.slice(0, 4)}-${seendate.slice(4, 6)}-${seendate.slice(6, 8)}T${seendate.slice(9, 11)}:${seendate.slice(11, 13)}:${seendate.slice(13, 15)}Z`
    );
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  } catch (error) {
    console.error('Date parsing error:', error);
    return 'Date unavailable';
  }
};

function RelatedNewsPage() {
  const { url } = useParams();
  const [relatedNews, setRelatedNews] = useState([]);
  const [articleContents, setArticleContents] = useState({});
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [category, setCategory] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  // 获取文章内容的函数
  const fetchArticleContent = useCallback(async (articleUrl) => {
    try {
      const contentResponse = await axios.post(
        `${apiUrl}/sort/getNewsContent/`,
        { article_url: articleUrl },
        { timeout: 10000 } // 10秒超时
      );
      
      if (contentResponse.data) {
        setArticleContents(prev => ({
          ...prev,
          [articleUrl]: contentResponse.data
        }));
      }
    } catch (error) {
      console.error(`Error fetching content for ${articleUrl}:`, error);
      setArticleContents(prev => ({
        ...prev,
        [articleUrl]: { error: true }
      }));
    }
  }, [apiUrl]);

  // 获取相关新闻的函数
  const fetchRelatedNews = useCallback(async () => {
    try {
      setLoading(true);
      setErrorMessage('');
      setArticleContents({});

      const storedFavorites = await getFavoritesFromIndexedDB();
      const favorite = storedFavorites.find(fav => fav.url === url);

      if (!favorite?.categoryFromGPT) {
        throw new Error('No category information found for this article.');
      }

      setCategory(favorite.categoryFromGPT);

      const response = await axios.post(
        `${apiUrl}/sort/getRelatedNews/`,
        { query: favorite.categoryFromGPT },
        { timeout: 15000 } // 15秒超时
      );

      if (!response.data?.length) {
        throw new Error('No related news found for this category.');
      }

      setRelatedNews(response.data);

      // 使用 Promise.allSettled 确保即使部分请求失败也能继续
      await Promise.allSettled(
        response.data
          .filter(article => article.url)
          .map(article => fetchArticleContent(article.url))
      );

    } catch (error) {
      setErrorMessage(error.message || 'An error occurred while fetching data.');
    } finally {
      setLoading(false);
    }
  }, [url, apiUrl, fetchArticleContent]);

  useEffect(() => {
    fetchRelatedNews();
  }, [fetchRelatedNews]);

  return (
    <div className="news-container">
      <h1 className="news-title">Related News for Category: {category}</h1>
      
      {errorMessage && (
        <ErrorMessage 
          message={errorMessage}
          onRetry={fetchRelatedNews}
        />
      )}

      {loading ? (
        <LoadingSpinner />
      ) : (
        <ul className="news-list">
          {relatedNews.map((news, index) => (
            <NewsCard
              key={news.url || index}
              news={news}
              articleContent={articleContents[news.url]}
            />
          ))}
        </ul>
      )}
    </div>
  );
}

export default RelatedNewsPage;